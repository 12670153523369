.skipButton {
  position: absolute;
  bottom: 20px;
  right: 20px;
  padding: 10px 20px;
  background-color: rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 5px;
  color: #000;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.skipButton:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
.launchScreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  background-image: linear-gradient(
      45deg,
      #f3f3f3 25%,
      transparent 25%,
      transparent 75%,
      #f3f3f3 75%,
      #f3f3f3
    ),
    linear-gradient(
      45deg,
      #f3f3f3 25%,
      transparent 25%,
      transparent 75%,
      #f3f3f3 75%,
      #f3f3f3
    );
  background-size: 60px 60px;
  background-position:
    0 0,
    30px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  opacity: 1;
  transition: opacity 0.5s ease-out;
}
.launchScreen.hidden {
  opacity: 0;
  pointer-events: none;
}

.logo {
  width: 150px;
  height: auto;
  animation: pulse 2s infinite;
}

.spinner {
  margin-top: 20px;
  width: 50px;
  height: 50px;
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top-color: #000;
  animation: spin 1s ease-in-out infinite;
  border-top-color: #007bff;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .logo {
    width: 100px; /* Smaller logo on mobile devices */
  }

  .spinner {
    width: 40px;
    height: 40px;
  }
}
